
<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <h1 class="sm:mx-0 mx-4 sm:mb-12 mb-8 text-5xl d-theme-heading-color">Internal Server Error!</h1>
            
            <vs-button size="large" @click="redirect">Back to Home</vs-button>
        </div>
    </div>
</template>

<script>

export default {
  methods: {
    redirect(){
      location.replace(window.location.origin)
    },
   },

  }
</script>